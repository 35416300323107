import { jsx as _jsx } from "react/jsx-runtime";
// components
import Image from '../../image';
export default function AvatarPreview({ file }) {
    if (!file) {
        return null;
    }
    const imgUrl = typeof file === 'string' ? file : file.preview;
    return (_jsx(Image, { alt: "avatar", src: imgUrl, sx: {
            zIndex: 8,
            overflow: 'hidden',
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 16px)`,
            height: `calc(100% - 16px)`,
        } }));
}
